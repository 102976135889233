import React from "react";
import styled from "styled-components";

import { TriangleDownIcon, TriangleUpIcon } from "./Icons";
interface IProps {
  readonly column: any;
}

const TableColumnSortWrapper = styled.span`
  position: relative;
  display: inline-block;
  width: 0.875rem;
  height: 0.938rem;
  > svg {
    position: absolute;
    top: -0.063rem;
    display: inline-block;
    transform: scale(0.8, 1);
  }
`;

const TableColumnSort: React.FC<IProps> = ({ column }) => {
  return (
    <TableColumnSortWrapper>
      {column.isSorted ? (
        column.isSortedDesc ? (
          <TriangleDownIcon />
        ) : (
          <TriangleUpIcon />
        )
      ) : (
        ""
      )}
    </TableColumnSortWrapper>
  );
};

export default TableColumnSort;
