const theme = {
  colors: {
    // branding: "#4A90E2",
    navigationBackground: "#fff",
    navigationItemBackground: "#fff",
    navigationItemFill: "#9fafbc",
    navigationItemHoverfill: "#00528c",
    navigationItemHoverBackground: "#f2f5f8",

    scrollerBackground: "#f2f5f8",

    // Body
    pageBackground: "#dee4e8",
    // bodyText: "#000",
    // darkBackgroundText: "#FFF",
    // pageBackground: "#F0F3F6",
    // sidebarPageBackground: "#FFF",
    errorText: "#ff4747",
    errorBackground: "#ffe9ea",
    successText: "#32424e",
    successBackground: "#f0f7e0",

    // Header
    headerText: "#fff",
    headerBackground: "#00528c",

    // Input
    inputLabel: "#677a89",
    inputText: "#32424e",
    inputBorder: "#9fafbc",
    inputBackground: "#fff",
    inputErrorText: "#e02c2c",
    inputErrorBorder: "#e02c2c",
    inputErrorBackground: "#ffe9ea",
    inputHoverText: "#32424e",
    inputHoverBorder: "#00c2de",
    inputHoverBackground: "#fff",
    inputFocusText: "#00c2de",
    inputFocusBorder: "#00c2de",
    inputFocusBackground: "#e9f5f5",

    // Radio
    radioLabel: "#677a89",
    radioBorder: "#9fafbc",
    radioBackground: "#0aa5ab",
    radioFocusBorder: "#0aa5ab",
    radioFocusBackground: "#dee4e8",

    // Checkbox
    checkboxLabel: "#677a89",
    checkboxBorder: "#9fafbc",
    checkboxBackground: "#fff",
    checkboxSelectedBorder: "#0aa5ab",
    checkboxSelectedBackground: "#0aa5ab",
    checkboxFocusBorder: "#0aa5ab",
    checkboxFocusBackground: "#dee4e8",

    // Select
    selectText: "#32424e",
    selectBorder: "#9fafbc",
    selectBackground: "#fff",
    selectErrorBackground: "#e20808",
    selectHoverBorder: "#00819d",
    selectHoverBackground: "#e9f5f5",
    selectMenuBorder: "#00819d",
    selectMenuBackground: "#fff",
    selectMenuItemHoverText: "#fff",
    selectMenuItemHoverBackground: "#00819d",
    selectArrorFill: "#00819d",

    // Link
    linkText: "#00819d",

    // Spinner
    spinnerBackground: "#fff",

    // Table
    tableBackground: "#fff",
    tableBorder: "#dee4e8",
    tableThBackground: "#c3cdd5",
    tableTrHoverBackground: "#f2f5f8",
    tableTrSelectedBackground: "#dee4e8",
    tableTdBorder: "#dee4e8",

    // Modal
    modalBackground: "#fff",
    modalHeaderBorder: "#e5e5e5",
    modalOverlayBackground: "rgba(0, 0, 0, 0.3)",

    // Button
    primaryButtonText: "#fff",
    primaryButtonBorder: "#00819d",
    primaryButtonBackground: "#00819d",
    primaryButtonHoverText: "#fff",
    primaryButtonHoverBorder: "#005B75",
    primaryButtonHoverBackground: "#005B75",
    primaryButtonActiveText: "#fff",
    primaryButtonActiveBorder: "#006a85",
    primaryButtonActiveBackground: "#006a85",
    primaryButtonDisabledText: "#fff",
    primaryButtonDisabledBorder: "#c3cdd5",
    primaryButtonDisabledBackground: "#c3cdd5",

    secondaryButtonText: "#202E39",
    secondaryButtonBorder: "#9fafbc",
    secondaryButtonBackground: "transparent",
    secondaryButtonHoverText: "#202E39",
    secondaryButtonHoverBorder: "#202E39",
    secondaryButtonHoverBackground: "transparent",
    secondaryButtonActiveText: "#202E39",
    secondaryButtonActiveBorder: "#202E39",
    secondaryButtonActiveBackground: "transparent",
    secondaryButtonDisabledText: "#9FAFBC",
    secondaryButtonDisabledBorder: "#9FAFBC",
    secondaryButtonDisabledBackground: "transparent",
  },
  fonts: {
    normal: "uni_neuebook, sans-serif",
    bold: "uni_neuebold, sans-serif",
    heavy: "uni_neueheavy, sans-serif",
  },
};

export default theme;
