import React from "react";
import ReactDOM from "react-dom";
import styled from "styled-components";
import { AnimatePresence, motion } from "framer-motion";
interface Attrs {
  role: string;
  "aria-modal": string;
}

const ModalOverlay = styled(motion.div).attrs<Attrs>({
  role: "dialog",
  "aria-modal": "true",
})`
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 403;
  background-color: ${({ theme }) => theme.colors.modalOverlayBackground};
`;

const ModalContent = styled(motion.div)`
  background-color: ${({ theme }) => theme.colors.modalBackground};
  border-radius: 0.25rem;
  z-index: 404;
`;

interface IProps {
  readonly isShowing: boolean;
  readonly children: React.ReactNode;
  readonly handleClose: (e: any) => void;
}

const Modal: React.FC<IProps> = ({ isShowing, handleClose, children }) => {
  return ReactDOM.createPortal(
    <AnimatePresence>
      {isShowing && (
        <ModalOverlay
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          onClick={(e) => {
            handleClose(e);
          }}
        >
          <ModalContent
            initial={{ y: 50 }}
            animate={{ y: 0 }}
            onClick={(e) => e.stopPropagation()}
          >
            {children}
          </ModalContent>
        </ModalOverlay>
      )}
    </AnimatePresence>,
    document.body
  );
};
export default Modal;
