import React, { useEffect, useRef } from "react";
import L from "leaflet";
import styled from "styled-components";
import "leaflet/dist/leaflet.css";
import { useTranslation } from "react-i18next";

import "../../i18n";

interface IProps {
  data: any[];
  selectedSiteId: any;
  isFullScreen: boolean;
  handleSiteIdClick: any;
}

const Map = styled.div<{ isFullScreen: boolean }>`
  width: ${(props) => (props.isFullScreen ? "100vw" : "calc(100vw - 3.5rem)")};
  min-height: 100vh;
`;

const DashboardMap: React.FC<IProps> = ({
  data,
  selectedSiteId,
  handleSiteIdClick,
  isFullScreen,
}) => {
  const { t } = useTranslation();

  const zoom = 16;
  const minZoom = 2.3;
  const maxBounds = L.latLngBounds([-90, -180], [90, 180]);
  const worldCopyJump = true;
  const zoomAnimation = false;
  const fadeAnimation = false;
  const attributionControl = false;

  let mapRef = useRef<any | null>(null);
  let markerScale = 0.8;
  let markersLayer = new L.LayerGroup();
  let selectedMarkerZindex = 1000000;
  let selectedLatLong: any[] = [29.142246, -90.212145];

  useEffect(() => {
    // @ts-ignore
    mapRef.current = L.map("map", {
      attributionControl,
      minZoom,
      maxBounds,
      worldCopyJump,
      zoomAnimation,
      fadeAnimation,
      zoom,
      center: [29.142246, -90.212145],
    });

    const mapLink = '<a href="http://www.esri.com/">Esri</a>';
    const wholink =
      "i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community";
    L.tileLayer(
      "https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}",
      {
        attribution: "&copy; " + mapLink + ", " + wholink,
        maxZoom: 18,
      }
    ).addTo(mapRef.current);

    mapRef.current.setView([29.142246, -90.212145], 4);
  }, []);

  useEffect(() => {
    createMarkerLayer(data);
  }, [data]);

  useEffect(() => {
    selectedSiteId && selectMarker(selectedSiteId);
  }, [selectedSiteId]);

  const mapMetricToColor = (metric: any) => {
    if (metric >= 3) {
      return "#539b38";
    } else if (metric > 0) {
      return "#f58410";
    } else {
      return "#b72025";
    }
  };

  const createMarkerLayer = (data: any) => {
    markersLayer.clearLayers();
    const element = mapRef.current
      .getContainer()
      .querySelector(".leaflet-marker-pane");
    element.innerHTML = "";

    data.forEach((item: any) => {
      const latitude = item.latitude;
      const longitude = item.longitude;

      if (!latitude || !longitude) return;

      const id = item.site_id;
      const heading = item.heading || 1;
      const metric = item.metric || 1;

      let marker;
      const metricColor = mapMetricToColor(metric);
      marker = L.marker([latitude, longitude], {
        icon: L.divIcon({
          html: `<svg id="_${id}" data-lat="${latitude}" data-lng="${longitude}" viewBox="0 0 72 72"
                      width="72" height="72" xmlns="http://www.w3.org/2000/svg" style="margin-top: -2.25rem; margin-left: -2.25rem; transform: scale\(${markerScale}\) rotate\(${heading}deg\);" class="marker">
                      <g class="selected-marker" visibility="hidden">
                          <circle cx="36" cy="36" r="25" stroke="#0095D9" stroke-width="3" fill="transparent" />
                          <circle cx="36" cy="36" r="35" stroke="#0095D9" stroke-width="2" fill="transparent" />
                      </g>
                      <g>
                          <circle cx="36" cy="36" r="18" stroke="${metricColor}" stroke-width="1" fill-opacity="0.7" fill="${metricColor}" />
                          <svg x="31.5" y="22" width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg" >
                            <path  fill-rule="evenodd" clip-rule="evenodd" d="M4.43241 0.00826509C4.32862 0.0317009 4.18284 0.172879 3.75968 0.659667C3.26067 1.23369 2.82236 1.86313 2.41583 2.58966C1.34082 4.51088 0.613518 6.95312 0.261566 9.82344C-0.105924 12.8206 -0.0866291 16.0645 0.322278 20.018C0.401526 20.7844 0.451753 21.187 0.606537 22.2965C0.672053 22.7661 0.696303 23.0771 0.754619 24.1974C0.794503 24.9634 0.822214 25.1538 0.913387 25.2886C1.05308 25.4951 1.23711 25.5505 1.78341 25.5511C1.99559 25.5513 2.30457 25.5592 2.46999 25.5686L2.77078 25.5856V25.6689V25.7523L2.99964 25.7681C3.1255 25.7768 3.28974 25.8031 3.36458 25.8265C3.64788 25.9151 3.92919 25.9717 4.18116 25.9909C4.53073 26.0175 5.06231 25.9836 5.30681 25.9191C5.41568 25.8904 5.59382 25.8428 5.70268 25.8135C5.81154 25.7842 5.97578 25.7601 6.06762 25.76L6.23463 25.7598V25.6686C6.23463 25.5791 6.23651 25.5775 6.33978 25.5753C7.14673 25.5581 7.74598 25.5264 7.83047 25.4963C7.88491 25.4769 7.97459 25.4136 8.02982 25.3558C8.18121 25.1969 8.20585 25.0678 8.25096 24.1974C8.30885 23.08 8.33322 22.7668 8.39889 22.2965C8.55239 21.1969 8.57985 20.9785 8.68382 20.031C9.0066 17.0893 9.08431 14.1167 8.90743 11.477C8.82519 10.25 8.57822 8.45629 8.3624 7.51891C8.34919 7.46163 8.31506 7.30929 8.28654 7.1804C7.63659 4.2435 6.38931 1.75533 4.74564 0.116696C4.64519 0.0165734 4.54667 -0.0175143 4.43241 0.00826509Z" fill="white"/>
                          </svg>
                      </g>
                  </svg>`,
          iconSize: [0, 0],
        }),
      })
        .on("click", () => {
          selectMarker(id);
        })
        .on("mouseover", () => {
          openPopup(item);
        })
        .on("mouseout", () => {
          closePopup();
        });

      markersLayer.addLayer(marker);
    });
    markersLayer.addTo(mapRef.current);
    selectMarker(selectedSiteId);
  };

  const openPopup = (item: any) => {
    const id = item.site_id;
    const metric = item.metric;
    const latitude = parseFloat(item.latitude) || 0;
    const longitude = parseFloat(item.longitude) || 0;
    L.popup()
      .setLatLng([latitude, longitude])
      .setContent(
        `<span>
              <span style="display: inline-block; width: 3.75rem;">
              ${t("common.siteId")}:</span> 
              <strong>${id}</strong><br>
              <span style="display: inline-block; width: 3.75rem;">${t(
                "common.metric"
              )}:</span> 
              <strong>${metric ? metric.toFixed(2) : 0}</strong><br>
              <span style="display: inline-block; width: 3.75rem;">${t(
                "common.latitude"
              )}:</span> 
              <strong>${latitude}</strong><br>
              <span style="display: inline-block; width: 3.75rem;">${t(
                "common.longitude"
              )}:</span> 
              <strong>${longitude}</strong>
            </span`
      )
      .openOn(mapRef.current);
  };

  const closePopup = () => {
    mapRef.current.closePopup();
  };

  const selectMarker = (siteId: number) => {
    drawSelectedMarker(siteId);
    handleSiteIdClick(siteId);
  };

  const drawSelectedMarker = (siteId: number) => {
    const element = mapRef.current.getContainer().querySelector(`#_${siteId}`);
    if (!element) return;
    const selectedMarker = element.getElementsByClassName("selected-marker");

    const latlng = [];
    const lat = parseFloat(element.getAttribute("data-lat")) || 0;
    const lng = parseFloat(element.getAttribute("data-lng")) || 0;

    latlng.push(lat);
    latlng.push(lng);

    selectedLatLong = latlng;
    mapRef.current.panTo(selectedLatLong);
    mapRef.current.on("zoomend", () => {
      mapRef.current.panTo(selectedLatLong);
    });
    deSelectMarker();

    if (selectedMarker[0]) {
      selectedMarker[0].setAttribute("visibility", "visible");
      selectedMarkerToFront(element);
    }
  };

  const selectedMarkerToFront = (element: any) => {
    selectedMarkerZindex += 1;

    element.parentNode.style.zIndex = selectedMarkerZindex;
  };

  const deSelectMarker = () => {
    const markers = mapRef.current
      .getContainer()
      .querySelectorAll('.selected-marker[visibility="visible"]');

    markers.forEach((marker: any) => {
      marker.setAttribute("visibility", "hidden");
    });
  };

  return <Map id="map" ref={mapRef} isFullScreen={isFullScreen}></Map>;
};

export default DashboardMap;
