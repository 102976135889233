import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import i18n from "i18next";
import { useTranslation } from "react-i18next";
import { systemTypes } from "../../data/systemTypes";
import { ISite } from "../../types/interfaces";
import "../../i18n";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  LoadingButton,
  Button,
  Input,
  SelectInput,
} from "../../components";

const FieldGroup = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1.25rem;
`;

const ModalContent = styled.div`
  width: 43.75rem;
`;

interface IProps {
  readonly isShowing: boolean;
  readonly isLoading: boolean;
  readonly handleClose: () => void;
  readonly createSite: any;
  readonly updateSite: any;
  readonly handleSubmitError: () => void;
  site: any;
  organizations: any;
}

const schema = yup.object().shape({
  name: yup.string().required(i18n.t("common.requiredField")),
  organizationId: yup.string().required(i18n.t("common.requiredField")),
  systemType: yup.string().required(i18n.t("common.requiredField")),
});

const SiteModal: React.FC<IProps> = ({
  isShowing,
  isLoading,
  handleClose,
  createSite,
  updateSite,
  site,
  organizations,
}) => {
  const [organizationOptions, setOrganizationOptions] = useState([]);
  useEffect(() => {
    const organizationOptions = organizations.map((organization: any) => {
      return {
        value: organization.id.toString(),
        label: organization.name,
      };
    });
    setOrganizationOptions(organizationOptions);
  }, [organizations]);

  const { t } = useTranslation();

  const { register, handleSubmit, errors } = useForm({
    defaultValues: site,
    resolver: yupResolver(schema),
  });

  const onSubmit = (site: ISite) => {
    site.id ? updateSite.mutate(site) : createSite.mutate(site);
  };

  return (
    <Modal isShowing={isShowing} handleClose={handleClose}>
      <ModalContent>
        <form onSubmit={handleSubmit(onSubmit)} data-testid="form">
          {site.id && <input type="hidden" name="id" ref={register} />}
          <ModalHeader
            headerText={site.id ? t("site.updateSite") : t("site.createSite")}
            handleClose={handleClose}
          />
          <ModalBody>
            <FieldGroup>
              <Input
                type="text"
                label={t("site.name")}
                name="name"
                errors={errors}
                register={register}
              />
              {organizationOptions.length && (
                <SelectInput
                  label={t("organization.organization")}
                  options={organizationOptions}
                  name="organizationId"
                  errors={errors}
                  register={register}
                />
              )}
            </FieldGroup>
            <FieldGroup>
              <SelectInput
                label={t("site.systemType")}
                options={systemTypes}
                name="systemType"
                errors={errors}
                register={register}
              />
            </FieldGroup>
          </ModalBody>
          <ModalFooter>
            <Button
              type="button"
              size="medium"
              variant="secondary"
              onClick={handleClose}
            >
              {t("common.cancel")}
            </Button>
            <LoadingButton
              type="submit"
              size="medium"
              isLoading={isLoading}
              isDisabled={false}
              variant="primary"
            >
              {t("common.submit")}
            </LoadingButton>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
};

export default SiteModal;
