import React from "react";
import styled from "styled-components";
import { get } from "lodash";

interface IProps {
  type?: string;
  name: string;
  value?: string;
  placeholder?: string;
  register?: any;
  errors?: any;
  label?: React.ReactNode;
  autoFocus?: boolean;
}

interface InputProps {
  readonly errors?: any;
  readonly name?: string;
}

const InputWrapper = styled.div`
  > label {
    display: block;
    color: ${({ theme }) => theme.colors.inputLabel};
    size: 1.125rem;
    padding-bottom: 0.188rem;
  }
  > span {
    display: inline-block;
    color: ${({ theme }) => theme.colors.inputErrorText};
    font-size: 0.938rem;
    margin-bottom: 0.5rem;
  }
`;

const StyledInput = styled.input<InputProps>`
  display: block;
  width: 100%;
  size: 1.125rem;
  padding: 0.688rem 0.75rem;
  border-radius: 0.313rem;
  color: ${({ theme }) => theme.colors.inputText};
  border: ${({ theme, errors, name }) =>
      get(errors, `${name}.message`)
        ? theme.colors.inputErrorBorder
        : theme.colors.inputBorder}
    solid 1px;
  background-color: ${({ theme, errors, name }) =>
    get(errors, `${name}.message`)
      ? theme.colors.inputErrorBackground
      : theme.colors.inputBackground};
  transition: all 0.2s;
  :hover {
    color: ${({ theme }) => theme.colors.inputHoverText} 1px solid;
    border: ${({ theme, errors, name }) =>
        get(errors, `${name}.message`)
          ? theme.colors.inputErrorBorder
          : theme.colors.inputHoverBorder}
      1px solid;
    background-color: ${({ theme, errors, name }) =>
      get(errors, `${name}.message`)
        ? theme.colors.inputErrorBackground
        : theme.colors.inputHoverBackground};
  }
  :focus {
    color: ${({ theme }) => theme.colors.inputFocusText} 1px solid;
    border: ${({ theme, errors, name }) =>
        get(errors, `${name}.message`)
          ? theme.colors.inputErrorBorder
          : theme.colors.inputFocusBorder}
      1px solid;
    background-color: ${({ theme }) => theme.colors.inputFocusBackground} 1px
      solid;
  }
`;

const Input: React.FC<IProps> = ({
  type,
  label,
  name,
  value,
  placeholder,
  errors,
  register,
  autoFocus,
}) => {
  return (
    <InputWrapper>
      {label && <label htmlFor={name}>{label}</label>}
      <StyledInput
        type={type}
        placeholder={placeholder}
        name={name}
        value={value}
        id={name}
        errors={errors}
        ref={register}
        autoFocus={autoFocus}
      ></StyledInput>
      {errors && <span>{get(errors, `${name}.message`)}</span>}
    </InputWrapper>
  );
};

export default Input;
