import { axiosInstance } from "../../../axiosInstance";
import { useMutation, useQueryClient } from "react-query";
import { queryKeys } from "../../../reactQuery/constants";

const useUpdateSite = () => {
  const queryClient = useQueryClient();
  const mutation = useMutation(
    (site: any) => axiosInstance.put(`/v1/sites/${site.id}`, site),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(queryKeys.sites);
      },
    }
  );

  return mutation;
};

export { useUpdateSite };
