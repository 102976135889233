import { axiosInstance } from "../../../axiosInstance";
import { useMutation, useQueryClient } from "react-query";
import { queryKeys } from "../../../reactQuery/constants";

const useDeleteSite = () => {
  const queryClient = useQueryClient();
  const mutation = useMutation(
    (siteId) => axiosInstance.delete(`/v1/sites/${siteId}`),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(queryKeys.sites);
      },
    }
  );

  return mutation;
};

export { useDeleteSite };
