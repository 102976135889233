import React from "react";
import styled from "styled-components";

import { Label } from "./";

interface IProps {
  label: string;
  readonly children: React.ReactNode;
}

const RadioGroupWrapper = styled.div`
  margin-top: 0.75rem;
  div {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(10rem, 1fr));
  }
`;

const RadioGroup: React.FC<IProps> = ({ label, children }) => {
  return (
    <RadioGroupWrapper>
      <Label>{label}</Label>
      <div>{children}</div>
    </RadioGroupWrapper>
  );
};

export default RadioGroup;
