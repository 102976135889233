import { axiosInstance } from "../../../axiosInstance";
import { useMutation, useQueryClient } from "react-query";
import { queryKeys } from "../../../reactQuery/constants";

const useCreateOrganization = () => {
  const queryClient = useQueryClient();
  const mutation = useMutation(
    (organization) => axiosInstance.post("/v1/organizations", organization),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(queryKeys.organizations);
      },
    }
  );
  return mutation;
};

export { useCreateOrganization };
