import styled from "styled-components";

interface IProps extends React.ButtonHTMLAttributes<HTMLLabelElement> {
  htmlFor?: string;
}

const Label = styled.label<IProps>`
  display: block;
  color: ${({ theme }) => theme.colors.inputLabel};
  size: 1.125rem;
  padding-bottom: 0.188rem;
`;

export { Label };
