import React from "react";
import styled from "styled-components";

import { TriangleUpIcon, TriangleDownIcon } from "./Icons";

const MenuItem = styled.li`
  position: relative;
  padding: 0.25rem;
  display: grid;
  > div {
    display: inline-block;
    position: absolute;
    top: 0.125rem;
    left: 0.25rem;
  }
  :hover {
    background-color: #efefef;
  }
  &.selected {
    background-color: #00819d;
    color: #efefef;
  }
`;

interface Item {
  hasNodes: boolean;
  isOpen: boolean;
  level: number;
  key: string;
  label: string;
  [name: string]: any;
}

interface IProps extends Item {
  active?: boolean;
  onClick: (event: React.MouseEvent<HTMLLIElement>) => void;
  toggleNode?: () => void;
}

const TreeMenuItem: React.FC<IProps> = ({
  hasNodes = false,
  isOpen = false,
  level = 0,
  onClick,
  toggleNode,
  active,
  focused,
  label = "unknown",
  style = {},
}) => (
  <MenuItem
    className={active ? "selected" : ""}
    style={{
      paddingLeft: `${1.875 + 0.625 * (hasNodes ? 0 : 1) + level * 0.625}rem`,
    }}
    role="button"
    aria-pressed={active}
    onClick={onClick}
  >
    {hasNodes && (
      <div
        onClick={(e) => {
          hasNodes && toggleNode && toggleNode();
          e.stopPropagation();
        }}
      >
        {isOpen ? <TriangleDownIcon /> : <TriangleUpIcon />}
      </div>
    )}
    {label}
  </MenuItem>
);

export default TreeMenuItem;
