import { useQuery, useQueryClient } from "react-query";

import { ISite } from "../../../types/interfaces";
import { axiosInstance } from "../../../axiosInstance";
import { queryKeys } from "../../../reactQuery/constants";

const getSites = async (): Promise<ISite[]> => {
  const { data } = await axiosInstance.get("/v1/sites");
  return data;
};

const useGetSites = () => {
  return useQuery(queryKeys.sites, getSites);
};

const usePrefetchSites = (): void => {
  const queryClient = useQueryClient();
  queryClient.prefetchQuery(queryKeys.sites, getSites);
};

export { useGetSites, usePrefetchSites };
