import React, { useEffect, useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import { useTranslation } from "react-i18next";

import "../../i18n";
import { useCreateSite } from "./hooks/useCreateSite";
import { useUpdateSite } from "./hooks/useUpdateSite";
import { useGetSites } from "./hooks/useGetSites";
import { useDeleteSite } from "./hooks/useDeleteSite";
import { useGetAllOrganizations } from "../organization/hooks/useGetOrganizations";
import useModal from "../../hooks/useModal";
import SiteTable from "./SiteTable";
import SiteModal from "./SiteModal";
import { ISite } from "../../types/interfaces";
interface IProps {
  groupCode: string | null;
}

const SitePage: React.FC<IProps> = ({ groupCode }) => {
  console.log("[APP] Site start");
  const { data = [], isLoading, isError, refetch } = useGetSites();
  const createSite = useCreateSite();
  const updateSite = useUpdateSite();
  const deleteSite = useDeleteSite();
  const { data: organizations = [] } = useGetAllOrganizations();
  const [site, setSite] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const {
    isShowing: isModalShowing,
    show: showModal,
    hide: hideModal,
  } = useModal();
  const { t } = useTranslation();

  useEffect(() => {
    if (groupCode) {
      refetch();
    }
  }, [groupCode]);

  useEffect(() => {
    if (isError) {
      toast.error(`${t("common.errorRetrievingData")}`);
    }
  }, [isError, t]);

  useEffect(() => {
    if (createSite.isSuccess) {
      toast.success(`${t("common.recordCreatedSuccessfully")}`);
      hideModal();
    }
    setIsSubmitting(createSite.isLoading);
  }, [createSite.isSuccess, createSite.isLoading, t]);

  useEffect(() => {
    if (updateSite.isSuccess) {
      toast.success(`${t("common.recordUpdatedSuccessfully")}`);
      hideModal();
    }
    setIsSubmitting(updateSite.isLoading);
  }, [updateSite.isSuccess, updateSite.isLoading, t]);

  useEffect(() => {
    if (deleteSite.isSuccess) {
      toast.success(`${t("common.recordDeletedSuccessfully")}`);
    }
  }, [deleteSite.isSuccess, t]);

  const handleSubmitError = (): void => {
    toast.error(`${t("common.errorSaving")}`);
  };

  const handleCreateSite = (): void => {
    setSite({ country: "US" });
    showModal();
  };

  const handleUpdateSite = (site: any): void => {
    setSite(site);
    showModal();
  };

  const handleDeleteSite = ({ id }: any): void => {
    deleteSite.mutate(id);
  };

  return (
    <>
      <SiteTable
        isLoading={isLoading}
        data={data}
        handleCreateSite={handleCreateSite}
        handleUpdateSite={handleUpdateSite}
        handleDeleteSite={handleDeleteSite}
      />
      {isModalShowing && (
        <SiteModal
          isShowing={isModalShowing}
          isLoading={isSubmitting}
          site={site}
          organizations={organizations}
          createSite={createSite}
          updateSite={updateSite}
          handleSubmitError={handleSubmitError}
          handleClose={hideModal}
        />
      )}
      <Toaster position="top-center" reverseOrder={true} />
    </>
  );
};

export default SitePage;
