import styled from "styled-components";

const Table = styled.table`
  border-spacing: 0;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.tableBackground};
  border-bottom: 1px solid ${({ theme }) => theme.colors.tableBorder};
  tr {
    transition: all 0.2s;
    &.selected {
      background-color: ${({ theme }) =>
        theme.colors.tableTrSelectedBackground};
    }
    :hover {
      background-color: ${({ theme }) => theme.colors.tableTrHoverBackground};
    }
    :last-child {
      td {
        border-bottom: 0;
      }
    }
  }
  th {
    position: sticky;
    z-index: 2;
    top: 0;
    padding: 0.7rem;
    text-align: left;
    font-weight: normal;
    background-color: ${({ theme }) => theme.colors.tableThBackground};
    vertical-align: center;
  }
  td {
    margin: 0;
    vertical-align: center;
    padding: 0.7rem;
    border-bottom: 1px solid ${({ theme }) => theme.colors.tableTdBorder};
    :last-child {
      border-right: 0;
    }
    > div {
      text-align: center;
      > svg {
        transform: scale(0.8);
        margin: -0.25rem 0;
      }
    }
  }
`;

export { Table };
