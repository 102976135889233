import React from "react";
import styled from "styled-components";

interface IProps {
  label: string;
  name: string;
  value: string;
  register?: any;
  checked?: boolean;
}

const RadioWrapper = styled.div`
  width: 100%;
  margin: 0.5rem auto;
  position: relative;
  display: block;
  label {
    position: relative;
    min-height: 2.125rem;
    display: block;
    padding-left: 2.5rem;
    margin-bottom: 0;
    cursor: pointer;
    span {
      position: absolute;
      top: 50%;
      color: ${({ theme }) => theme.colors.radioLabel};
      size: 1.125rem;
      transform: translateY(-50%);
    }
    &:before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      margin: 0.25rem;
      width: 1.5rem;
      height: 1.5rem;
      transition: transform 0.28s ease;
      border-radius: 50%;
      border: 1px solid ${({ theme }) => theme.colors.radioBorder};
    }
    &:after {
      content: "";
      display: block;
      width: 1rem;
      height: 1rem;
      border-radius: 50%;
      transform: scale(0);
      background-color: ${({ theme }) => theme.colors.radioBackground};
      transition: transform ease 0.2s;
      position: absolute;
      top: 0.563rem;
      left: 0.563rem;
    }
  }
  input[type="radio"] {
    width: auto;
    opacity: 0.00000001;
    position: absolute;
    left: 0;
    margin-left: -1.125rem;
    transition: all 0.2s;
    &:checked ~ label {
      &:after {
        transform: scale(1);
      }
    }
    &:focus + label::before {
      box-shadow: 0 0 0 0.375rem
        ${({ theme }) => theme.colors.radioFocusBackground};
      border: 1px solid ${({ theme }) => theme.colors.radioFocusBorder};
    }
  }
`;

const Radio: React.FC<IProps> = ({ label, name, value, checked, register }) => {
  return (
    <RadioWrapper>
      <input
        type="radio"
        name={name}
        value={value}
        id={value}
        defaultChecked={checked}
        ref={register}
      />
      <label htmlFor={value}>
        <span>{label}</span>
      </label>
    </RadioWrapper>
  );
};

export default Radio;
