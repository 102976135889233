import { useQuery, useQueryClient } from "react-query";
import axios from "axios";

import { IOrganization } from "../../../types/interfaces";
import { axiosInstance } from "../../../axiosInstance";
import { queryKeys } from "../../../reactQuery/constants";

const getOrganizations = async (): Promise<IOrganization[]> => {
  const { data } = await axiosInstance.get("/v1/organizations");
  return data;
};

const useGetOrganizations = () => {
  return useQuery(queryKeys.organizations, getOrganizations);
};

const getAllOrganizations = async (): Promise<IOrganization[]> => {
  const { data } = await axios.get("/v1/organizations", {
    baseURL:
      process.env.NODE_ENV === "production"
        ? "https://insights.maritime.viasat.com/api"
        : "http://localhost:8080/api",

    headers: {
      "Content-Type": "application/json",
      "Group-Code": "Maritime-Insights-Admin",
      Authorization: localStorage["token"]
        ? `Bearer ${localStorage["token"]}`
        : "",
      Environment: document.referrer.includes("/insights.viasat.com")
        ? "prod"
        : "test",
    },
  });
  return data;
};

const useGetAllOrganizations = () => {
  return useQuery(queryKeys.allOrganizations, getAllOrganizations);
};

const usePrefetchOrganizations = (): void => {
  const queryClient = useQueryClient();
  queryClient.prefetchQuery(queryKeys.organizations, getOrganizations);
};

export {
  useGetOrganizations,
  useGetAllOrganizations,
  usePrefetchOrganizations,
};
