import React, { useMemo, useEffect } from "react";
import { useTable, useSortBy } from "react-table";
import styled from "styled-components";
import ReactTooltip from "react-tooltip";
import { useTranslation } from "react-i18next";

import "../../i18n";
import { CheckCircle, Error } from "../../components/Icons";
import { TableColumnSort, Table, Spinner } from "../../components";

interface IProps {
  data: any[];
  handleSiteIdClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
  selectedSiteId: number | null;
  loading: boolean;
}

const Scroller = styled.div`
  height: calc(100vh - 6rem);
  position: relative;
  overflow-y: scroll;
`;

const DashboardTable: React.FC<IProps> = ({
  data,
  handleSiteIdClick,
  selectedSiteId,
  loading,
}) => {
  const { t } = useTranslation();

  useEffect(() => {
    ReactTooltip.rebuild();
  });

  const columns: any[] = useMemo(
    () => [
      {
        Header: t("common.siteId"),
        accessor: "site_id",
      },
      {
        Header: t("common.siteName"),
        accessor: "name",
      },
      {
        Header: t("dashboard.state"),
        accessor: "state",
        Cell: (props: any) => {
          return (
            <div
              data-tip={
                props.cell.value === 1
                  ? t("dashboard.online")
                  : t("dashboard.offline")
              }
              data-for="state"
            >
              {props.cell.value >= 1 ? <CheckCircle /> : <Error />}
            </div>
          );
        },
      },
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,

    prepareRow,
  } = useTable(
    {
      columns,
      data,
    },
    useSortBy
  );

  return (
    <>
      <Scroller>
        <Table {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                    <span>{column.render("Header")}</span>
                    <TableColumnSort column={column} />
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row, i) => {
              prepareRow(row);
              return (
                <tr
                  {...row.getRowProps()}
                  onClick={() => {
                    handleSiteIdClick(row.values.site_id as any);
                  }}
                  className={
                    row.values.siteId === selectedSiteId ? "selected" : ""
                  }
                >
                  {row.cells.map((cell) => {
                    return (
                      <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </Table>
        <Spinner isLoading={loading} />
      </Scroller>
      <ReactTooltip id="state" effect="solid" />
    </>
  );
};

export default DashboardTable;
