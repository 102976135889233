import React, { useEffect } from "react";
import { Switch, Route } from "react-router-dom";
import styled from "styled-components";
import { Organization, Site } from "../pages";
import { Navigation } from "./Navigation";
import { useGetAllOrganizations } from "../pages/organization/hooks/useGetOrganizations";

const PagesWrapper = styled.div`
  padding-left: 3.5rem;
  overflow: hidden;
`;

const Main = styled.main`
  background-color: ${({ theme }) => theme.colors.pageBackground};
  padding: 1.25rem;
`;

interface IProps {
  groupCode: string | null;
}

const Pages: React.FC<IProps> = ({ groupCode }) => {
  console.log("[APP] Page start");
  const allOrganizations = useGetAllOrganizations();

  useEffect(() => {
    if (groupCode) {
      allOrganizations.refetch();
    }
  }, [groupCode]);

  return (
    <PagesWrapper>
      <Navigation />
      <Main>
        <Switch>
          <Route path="/" exact>
            <Organization groupCode={groupCode} />
          </Route>
          <Route path="/site">
            <Site groupCode={groupCode} />
          </Route>
        </Switch>
      </Main>
    </PagesWrapper>
  );
};

export default Pages;
