import React from "react";
import styled from "styled-components";
import { get } from "lodash";
import { IOption } from "../types/interfaces";
import { ExpandDownIcon } from "./Icons";
interface IProps {
  type?: string;
  name: string;
  value?: string;
  options: IOption[];
  placeholder?: string;
  register?: any;
  errors?: any;
  label?: React.ReactNode;
  autoFocus?: boolean;
}

interface InputProps {
  readonly errors?: any[];
}

const InputWrapper = styled.div<InputProps>`
  > label {
    display: block;
    color: ${({ theme }) => theme.colors.inputLabel};
    size: 1.125rem;
    padding-bottom: 0.188rem;
  }
  > div {
    position: relative;
    > svg {
      position: absolute;
      display: inline-block;
      top: 0.625rem;
      right: 0.625rem;
      fill: #677a89;
    }
    > select {
      display: block;
      width: 100%;
      size: 1.125rem;
      appearance: none;
      padding: 0.688rem 2.2rem 0.688rem 0.75rem;
      border-radius: 0.313rem;
      color: ${({ theme }) => theme.colors.inputText};
      border: ${({ theme }) => theme.colors.inputBorder} 1px solid;
      background-color: ${({ theme, errors }) =>
        errors
          ? theme.colors.inputErrorBackground
          : theme.colors.inputBackground};
      transition: all 0.2s;
      :hover {
        color: ${({ theme }) => theme.colors.inputHoverText} 1px solid;
        border: ${({ theme }) => theme.colors.inputHoverBorder} 1px solid;
        background-color: ${({ theme }) => theme.colors.inputHoverBackground};
      }
      :focus {
        color: ${({ theme }) => theme.colors.inputFocusText} 1px solid;
        border: ${({ theme }) => theme.colors.inputFocusBorder} 1px solid;
        background-color: ${({ theme }) => theme.colors.inputFocusBackground}
          1px solid;
      }
    }
  }
  > span {
    display: inline-block;
    color: ${({ theme }) => theme.colors.inputErrorText};
    font-size: 0.938rem;
    margin-top: 0.5rem;
  }
`;

const SelectInput: React.FC<IProps> = ({
  label,
  name,
  value,
  options,
  placeholder,
  errors,
  register,
  autoFocus,
}) => {
  return (
    <InputWrapper>
      {label && <label htmlFor={name}>{label}</label>}
      <div>
        <select
          placeholder={placeholder}
          name={name}
          value={value}
          id={name}
          ref={register}
          autoFocus={autoFocus}
        >
          {options.map((option: IOption) => (
            <option value={option.value} key={option.value}>
              {option.label}
            </option>
          ))}
        </select>
        <ExpandDownIcon />
      </div>
      {errors && <span>{get(errors, `${name}.message`)}</span>}
    </InputWrapper>
  );
};

export default SelectInput;
