import React, { useState, useEffect } from "react";
import { spaComs } from "@viasat/insights-spa-package";

import Pages from "./Pages";

interface OuterAppWrapperProps {
  hotelComsCompleteOverride?: boolean;
}

const OuterAppWrapper: React.FC<OuterAppWrapperProps> = ({
  hotelComsCompleteOverride = false,
}) => {
  console.log("[APP] OuterAppWrapper start");
  const [hotelComsComplete, setHotelComsComplete] = useState<boolean>(
    hotelComsCompleteOverride
  );

  const [groupCode, setGroupCode] = useState<string | null>(null);

  useEffect(() => {
    console.log("[APP] OuterAppWrapper spaComs.init");
    spaComs.init(spaInitCallback, setGroupCode);
  }, []);

  useEffect(() => {
    if (groupCode) {
      sessionStorage.groupCode = groupCode;
    }
  }, [groupCode]);

  console.log("[APP] before spaInitCallback");

  const spaInitCallback = (
    token: string,
    groupCode: string,
    location: string
  ) => {
    sessionStorage.iframeLocation = location;

    if (localStorage.token && localStorage.token !== token) {
      //localLogout(); // This happens at a new login. This should clear the application session and local storage as necessary.
    }

    console.log("[APP] spaInitCallback token ", token);
    console.log("[APP] spaInitCallback groupCode ", groupCode);
    console.log("[APP] spaInitCallback location ", location);

    localStorage.token = token;
    sessionStorage.groupCode = groupCode;

    setGroupCode(groupCode);
    setHotelComsComplete(true);
  };

  console.log("[APP] OuterAppWrapper return");
  console.log(
    "[APP] OuterAppWrapper return hotelComsComplete: ",
    hotelComsComplete
  );
  console.log("[APP] OuterAppWrapper return groupCode: ", groupCode);
  return (
    <>
      {hotelComsComplete && groupCode ? <Pages groupCode={groupCode} /> : <></>}
    </>
  );
};

export default OuterAppWrapper;
