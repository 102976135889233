import { axiosInstance } from "../../../axiosInstance";
import { useMutation, useQueryClient } from "react-query";
import { queryKeys } from "../../../reactQuery/constants";

const useCreateSite = () => {
  const queryClient = useQueryClient();
  const mutation = useMutation(
    (site) => axiosInstance.post("/v1/sites", site),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(queryKeys.sites);
      },
    }
  );
  return mutation;
};

export { useCreateSite };
