import React, { useEffect, useState } from "react";
import styled from "styled-components";
import ReactTooltip from "react-tooltip";
import { Duration } from "luxon";
import { useTranslation } from "react-i18next";

import "../../i18n";

import {
  ArrowUp,
  ArrowDown,
  CheckCircle,
  Wifi,
  Error,
} from "../../components/Icons";

const DetailWrapper = styled.div<{ latency: number; healthyUplink: any }>`
  position: relative;
  > .select-site {
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 2;
    > svg {
      opacity: 0.06;
      position: absolute;
      transform: scale(8);
      top: 6.25rem;
      left: 10rem;
      z-index: 1;
    }
  }
  > .site-name {
    padding: 0.625rem;
    font-weight: 600;
    font-size: 1.188rem;
  }
  > div {
    display: grid;
    &.stats-2-col {
      grid-template-columns: auto auto;
      margin-bottom: 0.625rem;
      > div {
        > div {
          color: #677a89;
        }
      }
    }
    &.stats-3-col {
      grid-template-columns: auto auto auto;
      margin-bottom: 0.625rem;
      > div {
        > div {
          color: #677a89;
        }
      }
    }
    &.uplinks-state {
      border-top: 1px solid #dee4e8;
      background-color: #f2f5f8;
      padding: 0 0.625rem;
      display: flex;
      height: 4.125rem;
      justify-content: space-between;
      > div {
        display: inline-block;
        margin-top: auto;
        text-align: center;
        > .uplinks {
          display: flex;
          justify-content: left;
          margin-left: -0.5rem;
          > div {
            margin: 0 0.5rem;
          }
        }
      }
    }
    > div {
      padding: 0.313rem;
      > .no-data {
        display: block;
        line-height: 1.725rem;
        text-align: center;
      }
      > h2 {
        display: block;
        text-align: center;
        > span {
          font-size: 0.938rem;
        }
        &.latency {
          color: ${(props) =>
            props.latency >= 1200
              ? "#b72025"
              : props.latency >= 800
              ? "#f58410"
              : "#539b38"};
        }
        &.healthy-uplink {
          color: ${(props) =>
            props.healthyUplink >= 2
              ? "#539b38"
              : props.healthyUplink >= 1
              ? "#f58410"
              : "#b72025"};
        }
        &.metric {
          color: ${(props) =>
            props.healthyUplink >= 3
              ? "#539b38"
              : props.healthyUplink > 0
              ? "#f58410"
              : "#b72025"};
        }
      }
      > div {
        display: block;
        text-align: center;
        font-size: 0.813rem;
      }
    }
  }
`;

interface IProps {
  sites: any[];
  selectedSiteId: number | null;
}

const DashboardDetail: React.FC<IProps> = ({ sites, selectedSiteId }) => {
  const [
    {
      site_id,
      name,
      usage,
      latency,
      uplinks,
      metric,
      state,
      uptime,
      healthyUplink,
    },
    setSite,
  ] = useState({
    site_id: null,
    name: null,
    usage: 0,
    latency: 0,
    uplinks: [],
    metric: 0,
    state: 0,
    uptime: 0,
    healthyUplink: 0,
  });

  const { t } = useTranslation();

  useEffect(() => {
    if (sites.length && selectedSiteId) {
      const site = sites.find((site) => site.site_id == selectedSiteId);
      setSite(site);
    }
  }, [sites, selectedSiteId]);

  useEffect(() => {
    ReactTooltip.rebuild();
  });

  const uplinkItems = uplinks.map((uplink: any) => {
    return (
      <div key={uplink.label}>
        <div>{uplink.label}</div>
        <div
          data-tip={uplink.state === 1 ? t("common.up") : t("common.down")}
          data-for="state"
        >
          {uplink.state === 1 ? <ArrowUp /> : <ArrowDown />}
        </div>
      </div>
    );
  });

  const noDataMessage = () => (
    <span className="no-data">{t("common.noData")}</span>
  );

  const formatBytes = (bytes: any, decimals = 2) => {
    if (bytes === 0) return 0;
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return (
      <h2>
        {parseFloat((bytes / Math.pow(k, i)).toFixed(dm))}{" "}
        <span>{sizes[i]}</span>
      </h2>
    );
  };

  const getDurationFromMilliseconds = (uptime: any) => {
    const {
      years,
      months,
      days,
      hours,
      minutes,
      seconds,
    } = Duration.fromObject({
      seconds: uptime,
    }).shiftTo("years", "months", "days", "hours", "minutes", "seconds");

    return (
      <h2>
        {years > 0 && (
          <>
            {years} <span>y </span>
          </>
        )}
        {months > 0 && (
          <>
            {months} <span>m </span>
          </>
        )}
        {days > 0 && (
          <>
            {days} <span>d </span>
          </>
        )}
        {hours}:{minutes}:{seconds.toFixed()}
      </h2>
    );
  };

  return (
    <>
      <DetailWrapper latency={latency} healthyUplink={healthyUplink}>
        {!selectedSiteId && (
          <div className="select-site">
            {t("dashboard.selectASiteToViewDetails")} <Wifi />
          </div>
        )}
        <div className="site-name">
          {name} ({site_id})
        </div>
        <div className="stats-2-col">
          <div>
            {state ? getDurationFromMilliseconds(uptime) : noDataMessage()}
            <div>{t("dashboard.uptime")}</div>
          </div>
          <div>
            {state ? formatBytes(usage) : noDataMessage()}
            <div>{t("dashboard.usageOver24Hours")}</div>
          </div>
        </div>
        <div className="stats-3-col">
          <div>
            {latency !== null ? (
              <h2 className={state ? "latency" : ""}>
                {latency?.toFixed()}
                {latency && <span>&nbsp;ms</span>}
              </h2>
            ) : (
              noDataMessage()
            )}
            <div>{t("dashboard.latency")}</div>
          </div>
          <div>
            {healthyUplink !== null ? (
              <h2 className={state ? "healthy-uplink" : ""}>{healthyUplink}</h2>
            ) : (
              noDataMessage()
            )}
            <div>{t("dashboard.healthyUplinks")}</div>
          </div>
          <div>
            {metric !== null ? (
              <h2 className={state ? "metric" : ""}>{metric?.toFixed(2)}</h2>
            ) : (
              noDataMessage()
            )}
            <div>{t("dashboard.metric")}</div>
          </div>
        </div>
        <div className="uplinks-state">
          <div>
            {metric !== null ? (
              <div className="uplinks">{uplinkItems}</div>
            ) : (
              noDataMessage()
            )}
            <div>{t("dashboard.uplinks")}</div>
          </div>
          <div>
            <div data-tip={state >= 1 ? "Online" : "Offline"} data-for="state">
              {state >= 1 ? <CheckCircle /> : <Error />}
            </div>
            <div>{t("dashboard.state")}</div>
          </div>
        </div>
      </DetailWrapper>
      <ReactTooltip id="state" effect="solid" />
    </>
  );
};

export default DashboardDetail;
