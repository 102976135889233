import React, { useMemo, useEffect } from "react";
import { useTable, useSortBy } from "react-table";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import ReactTooltip from "react-tooltip";

import "../../i18n";
import {
  Table,
  TableActions,
  TableColumnSort,
  IconButton,
  Scroller,
  Spinner,
  Button,
} from "../../components";
import { EditIcon, DeleteIcon } from "../../components/Icons";
import { Can } from "../../components/Abilities";
import { countryCodeToCountryName } from "../../utils";
import { IOrganization } from "../../types/interfaces";
interface IProps {
  isLoading: boolean;
  data: IOrganization[];
  handleCreateOrganization: () => void;
  handleUpdateOrganization: (organization: IOrganization) => void;
  handleDeleteOrganization: (organization: IOrganization) => void;
}

const TableHeader = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  padding-bottom: 0.6rem;
`;

const OrganizationTable: React.FC<IProps> = ({
  isLoading,
  data,
  handleCreateOrganization,
  handleUpdateOrganization,
  handleDeleteOrganization,
}) => {
  const { t } = useTranslation();

  useEffect(() => {
    ReactTooltip.rebuild();
  });

  const columns: any[] = useMemo(
    () => [
      {
        Header: t("common.name"),
        accessor: "name",
      },
      {
        Header: t("common.groupCode"),
        accessor: "code",
      },
      {
        Header: t("common.address"),
        Cell: ({ row }: any) => {
          return `
            ${
              row.original.address2
                ? `${row.original.address1}`
                : `${row.original.address1},`
            }
             ${row.original.address2 ? `${row.original.address2},` : ""}
             ${row.original.city || ""}
             ${row.original.territory || ""}
             ${row.original.postcode || ""}
             ${countryCodeToCountryName(row.original.country)?.label || ""}
            `;
        },
      },
      {
        Header: t("common.actions"),
        Cell: ({ row }: any) => {
          return (
            <TableActions>
              {/* <Can I="update" a="Organization"> */}
              <IconButton
                data-for="action"
                data-tip={t("common.update")}
                data-testid="update"
                onClick={() => handleUpdateOrganization(row.original)}
              >
                <EditIcon />
              </IconButton>
              {/* </Can> */}
              {/* <Can I="delete" a="Organization">
                <IconButton
                  data-for="action"
                  data-tip={t("common.delete")}
                  data-testid="delete"
                  onClick={() => handleDeleteOrganization(row.original)}
                >
                  <DeleteIcon />
                </IconButton>
              </Can> */}
            </TableActions>
          );
        },
      },
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
    },
    useSortBy
  );

  return (
    <>
      <TableHeader>
        <h2>{t("organization.organizations")}</h2>
        {/* <Can I="create" a="Organization">
          <div>
            <Button
              onClick={handleCreateOrganization}
              size="small"
              variant="primary"
            >
              {t("organization.createOrganization")}
            </Button>
          </div>
        </Can> */}
      </TableHeader>
      <Scroller>
        <Table {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                    <span>{column.render("Header")}</span>
                    <TableColumnSort column={column} />
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row, i) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </Table>
        {/* <Spinner isLoading={isLoading} /> */}
      </Scroller>
      <ReactTooltip id="action" effect="solid" />
    </>
  );
};

export default OrganizationTable;
