import styled from "styled-components";

interface IProps {
  disabled?: boolean;
  variant?: string;
  type?: string;
  onClick?: (e: any) => void;
  size?: string;
}

const Button = styled.button<IProps>`
  border-radius: 1.625rem;
  height: ${({ size }) =>
    size === "small" ? "2rem" : size === "medium" ? "2.25rem" : "2.5rem"};
  font-size: ${({ size }) =>
    size === "small" ? "0.875rem" : size === "medium" ? "1rem" : "1.125rem"};
  padding: 0
    ${({ size }) =>
      size === "small" ? "1.25rem" : size === "medium" ? "1.5rem" : "1.75rem"};
  color: ${({ theme, variant }) =>
    variant === "primary"
      ? theme.colors.primaryButtonText
      : theme.colors.secondaryButtonText};
  border: ${({ theme, variant }) =>
      variant === "primary"
        ? theme.colors.primaryButtonBorder
        : theme.colors.secondaryButtonBorder}
    solid 1px;
  background-color: ${({ theme, variant }) =>
    variant === "primary"
      ? theme.colors.primaryButtonBackground
      : theme.colors.secondaryButtonBackground};
  text-transform: uppercase;
  &:hover {
    color: ${({ theme, variant }) =>
      variant === "primary"
        ? theme.colors.primaryButtonHoverText
        : theme.colors.secondaryButtonHoverText};
    border: ${({ theme, variant }) =>
        variant === "primary"
          ? theme.colors.primaryButtonHoverBorder
          : theme.colors.secondaryButtonHoverBorder}
      solid 1px;
    background-color: ${({ theme, variant }) =>
      variant === "primary"
        ? theme.colors.primaryButtonHoverBackground
        : theme.colors.secondaryButtonHoverBackground};
  }
  &:active {
    color: ${({ theme, variant }) =>
      variant === "primary"
        ? theme.colors.primaryButtonActiveText
        : theme.colors.secondaryButtonActiveText};
    border: ${({ theme, variant }) =>
        variant === "primary"
          ? theme.colors.primaryButtonActiveBorder
          : theme.colors.secondaryButtonActiveBorder}
      solid 1px;
    background-color: ${({ theme, variant }) =>
      variant === "primary"
        ? theme.colors.primaryButtonActiveBackground
        : theme.colors.secondaryButtonActiveBackground};
  }
  &:disabled {
    color: ${({ theme, variant }) =>
      variant === "primary"
        ? theme.colors.primaryButtonDisabledText
        : theme.colors.secondaryButtonDisabledText};
    border: ${({ theme, variant }) =>
        variant === "primary"
          ? theme.colors.primaryButtonDisabledBorder
          : theme.colors.secondaryButtonDisabledBorder}
      solid 1px;
    background-color: ${({ theme, variant }) =>
      variant === "primary"
        ? theme.colors.primaryButtonDisabledBackground
        : theme.colors.secondaryButtonDisabledBackground};
  }
`;

export { Button };
