import { createGlobalStyle } from "styled-components";
import styledNormalize from "styled-normalize";

const GlobalStyle = createGlobalStyle`

  ${styledNormalize}

* {
  box-sizing: border-box;
}


  @font-face {
  font-family: "uni_neuebook";
  src: url(/unineue-book-webfont.eot);
  src: url(/fonts/unineue-book-webfont.eot?#iefix) format("embedded-opentype"),
    url(/fonts/unineue-book-webfont.woff2) format("woff2"),
    url(/fonts/unineue-book-webfont.woff) format("woff"),
    url(/fonts/unineue-book-webfont.ttf) format("truetype"),
    url(/fonts/unineue-book-webfont.svg#uni_neuebook) format("svg");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "uni_neuebold";
  src: url(/fonts/unineue-bold-webfont.eot);
  src: url(/fonts/unineue-bold-webfont.eot?#iefix) format("embedded-opentype"),
    url(/fonts/unineue-bold-webfont.woff2) format("woff2"),
    url(/fonts/unineue-bold-webfont.woff) format("woff"),
    url(/fonts/unineue-bold-webfont.ttf) format("truetype"),
    url(/fonts/unineue-bold-webfont.svg#uni_neuebold) format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "uni_neueheavy";
  src: url(/fonts/unineue-heavy-webfont.eot);
  src: url(/fonts/unineue-heavy-webfont.eot?#iefix) format("embedded-opentype"),
    url(/fonts/unineue-heavy-webfont.woff2) format("woff2"),
    url(/fonts/unineue-heavy-webfont.woff) format("woff"),
    url(/fonts/unineue-heavy-webfont.ttf) format("truetype"),
    url(/fonts/unineue-heavy-webfont.svg#uni_neueheavy) format("svg");
  font-weight: normal;
  font-style: normal;
}

/* latin */
@font-face {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  src: local("Source Sans Pro Regular"), local("SourceSansPro-Regular"),
    url(/fonts/6xK3dSBYKcSV-LCoeQqfX1RYOo3qOK7lujVj9w.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}

/* latin */
@font-face {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 600;
  src: local("Source Sans Pro SemiBold"), local("SourceSansPro-SemiBold"),
    url(/fonts/6xKydSBYKcSV-LCoeQqfX1RYOo3i54rwlxdu3cOWxw.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}

/* latin */
@font-face {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 700;
  src: local("Source Sans Pro Bold"), local("SourceSansPro-Bold"),
    url(/fonts/6xKydSBYKcSV-LCoeQqfX1RYOo3ig4vwlxdu3cOWxw.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}


    html {
      font-size: 16px;
        *:focus {outline:none !important}
    }

    body {
        color: #32424E;
        background-color: #fff;
        font-family: 'Source Sans Pro', 'Helvetica Neue', Helvetica, Arial, sans-serif;
        text-rendering: optimizeLegibility;
        -webkit-font-smoothing: subpixel-antialiased;;
        -moz-osx-font-smoothing: grayscale;
        margin: 0;
    } 

    h1, h2, h3, h4, h5, h6 {
      font-family: uni_neuebook, sans-serif;
      margin: 0;
    }

    h1 {
        height: 1.6rem;
        width: 100%;
        color: #202E39;
        font-size: 1.75rem;
        font-weight: 200;
        text-align: center;
    }

    h2 {
      font-size: 1.4rem;
    }

    hr {
        margin-top: 1.2rem;
        margin-bottom: 2.4rem;
        width: 100%;
        height: .1px;
        opacity: 0.33;
        background-color: rgb(139, 142, 147);
    }

    .muted {
      opacity: 0.3;
    }

`;

export default GlobalStyle;
