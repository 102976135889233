import React from "react";
import styled from "styled-components";

import { CloseIcon } from "./Icons";
import { IconButton } from "./IconButton";

const StyledModalHeader = styled.div`
  border-bottom: solid 1px ${({ theme }) => theme.colors.modalHeaderBorder};
  display: grid;
  grid-template-columns: 1fr auto;
  padding: 1rem 1rem 1rem 2rem;
  > h2 {
    text-align: left;
    line-height: 2rem;
    vertical-align: middle;
  }
`;

interface IProps {
  readonly headerText: string;
  readonly handleClose: (e: any) => void;
}

const ModalHeader: React.FC<IProps> = ({ headerText, handleClose }) => {
  return (
    <StyledModalHeader>
      <h2>{headerText}</h2>
      <IconButton onClick={handleClose}>
        <CloseIcon />
      </IconButton>
    </StyledModalHeader>
  );
};
export default ModalHeader;
