import styled from "styled-components";

const Scroller = styled.div`
  height: calc(100vh - 5rem);
  overflow-y: scroll;
  background-color: ${({ theme }) => theme.colors.scrollerBackground};
  position: relative;
`;

export { Scroller };
