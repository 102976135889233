import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { Select } from "./";

interface IProps {
  register: any;
}

enum Type {
  SAILOR_600,
  SAILOR_900,
}

interface IServicePlan {
  id: string;
  type: Type;
  priorityData: number;
  monthlyPrice: string;
  peopleCount: number;
  antenna: string;
}

const SelectServicePlan: React.FC<IProps> = ({ register }) => {
  const { t } = useTranslation();
  const [servicePlans, setServicePlans] = useState([]);

  return (
    <Select
      name="servicePlan"
      id="servicePlan"
      items={servicePlans}
      label={t("activate.servicePlan")}
      register={register}
    ></Select>
  );
};

export default SelectServicePlan;
