import { Duration } from "luxon";
import { countries } from "./data/countries";

// Get duration from milliseconds
export const getDurationFromMilliseconds = (uptime: any) => {
  const { years, months, days, hours, minutes, seconds } = Duration.fromObject({
    seconds: uptime,
  }).shiftTo("years", "months", "days", "hours", "minutes", "seconds");
  let duration = "";
  if (years) duration = duration + `${years} years `;
  if (months) duration = duration + `${months} months `;
  if (days) duration = duration + `${days} days `;
  if (hours) duration = duration + `${hours}`;
  if (minutes) duration = duration + `:${minutes}`;
  if (seconds) duration = duration + `:${seconds.toFixed()}`;

  return duration;
};

// Country Code To Country Name
export const countryCodeToCountryName = (countryCode: string) => {
  return countries.find((country) => country.value === countryCode);
};
