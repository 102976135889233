import React from "react";
import Downshift from "downshift";
import styled from "styled-components";
import { get } from "lodash";

import { Label } from "./";

const SelectWrapper = styled.div`
  padding-bottom: 0.188rem;
  > span {
    display: inline-block;
    color: ${({ theme }) => theme.colors.errorText};
    font-size: 0.938rem;
    margin-top: 0.5rem;
  }
`;

const Input = styled.div`
  position: relative;
  width: 100%;
  input {
    display: block;
    width: 100%;
    color: ${({ theme }) => theme.colors.selectText};
    size: 1.125rem;
    padding: 0.688rem 0.75rem;
    border-radius: 0.313rem;
    border: ${({ theme }) => theme.colors.selectBorder} 1px solid;
    background-color: ${(isError) =>
      !isError
        ? ({ theme }) => theme.colors.selectErrorBackground
        : ({ theme }) => theme.colors.selectBackground};
    transition: all 0.2s;
    text-align: left;
    cursor: pointer;
    :hover {
      border: ${({ theme }) => theme.colors.selectHoverBorder}1px solid;
    }
    &.open {
      border-radius: 0.313rem 0.313rem 0 0;
      border: ${({ theme }) => theme.colors.selectHoverBorder} 1px solid;
      background-color: ${({ theme }) => theme.colors.selectHoverBackground};
    }
  }

  > .arrow {
    position: absolute;
    top: 1.125rem;
    right: 1.125rem;
    width: 0;
    height: 0;
    border-left: 0.313rem solid transparent;
    border-right: 0.313rem solid transparent;
    border-top: 0.313rem solid ${({ theme }) => theme.colors.selectArrorFill};
    transition: all 0.2s;
    &.open {
      transform: rotate(180deg);
    }
  }
`;

const Menu = styled.ul`
  list-style-type: none;
  position: absolute;
  z-index: 2;
  top: 1.938rem;
  right: 0;
  left: 0;
  border: ${({ theme }) => theme.colors.selectMenuBorder} 1px solid;
  background-color: ${({ theme }) => theme.colors.selectMenuBackground};
  border-radius: 0 0 0.313rem 0.313rem;
  margin: 0;
  margin-top: 0.625rem;
  padding: 0;
`;

const Items = styled.li`
  padding: 0.5rem 0.75rem;
  :hover {
    color: ${({ theme }) => theme.colors.selectMenuItemHoverText};
    background-color: ${({ theme }) =>
      theme.colors.selectMenuItemHoverBackground};
  }
`;

interface IProps {
  items: any[];
  label: string;
  name: string;
  value?: string;
  id: string;
  register?: any;
  errors?: any;
}

const Select: React.FC<IProps> = ({
  items,
  label,
  name,
  value,
  id,
  errors,
  register,
}) => {
  return (
    <SelectWrapper>
      <Downshift initialInputValue={value} id={id}>
        {({
          getInputProps,
          getItemProps,
          getLabelProps,
          getMenuProps,
          getToggleButtonProps,
          isOpen,
          highlightedIndex,
          selectedItem,
        }) => (
          <div>
            <Label htmlFor={id}>{label}</Label>
            <Input>
              <input
                className={isOpen ? "open" : "close"}
                {...getToggleButtonProps()}
                name={name}
                ref={register}
                {...getInputProps()}
              />

              <div className={isOpen ? "arrow open" : "arrow"}></div>
              {isOpen ? (
                <Menu {...getMenuProps()}>
                  {items.map((item, index) => (
                    <Items
                      highlighted={highlightedIndex === index}
                      selected={selectedItem === item.value}
                      {...getItemProps({
                        key: item.value,
                        index,
                        item: item.label,
                      })}
                    >
                      {item.label}
                    </Items>
                  ))}
                </Menu>
              ) : null}
            </Input>
          </div>
        )}
      </Downshift>
      {errors && <span>{get(errors, `${name}.message`)}</span>}
    </SelectWrapper>
  );
};

export default Select;
