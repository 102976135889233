import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import ReactTooltip from "react-tooltip";
import { useTranslation } from "react-i18next";

import { Can } from "../components/Abilities";
import { DashboardIcon, MapIcon, OrganizationIcon, SiteIcon } from "./Icons";

interface IProps {
  to: string;
  icon: any;
  text: string;
  subject: string;
}

const StyledNavLink = styled(NavLink)`
  background-color: ${({ theme }) => theme.colors.navigationItemBackground};
  height: 3.5rem;
  width: 3.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.2s;
  fill: ${({ theme }) => theme.colors.navigationItemFill};
  &:hover,
  &.active {
    background-color: ${({ theme }) =>
      theme.colors.navigationItemHoverBackground};
    svg {
      fill: ${({ theme }) => theme.colors.navigationItemHoverfill};
    }
  }
`;

const NavigationItem = ({ to, icon, text, subject }: IProps) => {
  useEffect(() => {
    ReactTooltip.rebuild();
  });

  const { t } = useTranslation();

  const component = {
    DashboardIcon,
    MapIcon,
    OrganizationIcon,
    SiteIcon,
  };
  const Icon = component[icon];

  return (
    // <Can I="read" a={subject}>
    <>
      <div data-tip={t(`${text}`)} data-for={icon}>
        <StyledNavLink to={to} activeClassName="active" exact>
          <Icon />
        </StyledNavLink>
      </div>
      <ReactTooltip id={icon} effect="solid" />
    </>
    // </Can>
  );
};

export { NavigationItem };
