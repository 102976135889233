import React from "react";
import styled from "styled-components";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import i18n from "i18next";
import { useTranslation } from "react-i18next";

import { countries } from "../../data/countries";
import { IOrganization } from "../../types/interfaces";
import "../../i18n";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  LoadingButton,
  Button,
  Input,
  SelectInput,
} from "../../components";

const FieldGroup = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1.25rem;
`;

const ModalContent = styled.div`
  width: 43.75rem;
`;

interface IProps {
  readonly isShowing: boolean;
  readonly isLoading: boolean;
  readonly handleClose: () => void;
  readonly createOrganization: any;
  readonly updateOrganization: any;
  readonly handleSubmitError: () => void;
  organization: any;
}

const schema = yup.object().shape({
  name: yup.string().required(i18n.t("common.requiredField")),
  code: yup.string().required(i18n.t("common.requiredField")),
  address1: yup.string().required(i18n.t("common.requiredField")),
  city: yup.string().required(i18n.t("common.requiredField")),
  territory: yup.string().required(i18n.t("common.requiredField")),
  postcode: yup.string().required(i18n.t("common.requiredField")),
  country: yup.string().required(i18n.t("common.requiredField")),
});

const OrganizationModal: React.FC<IProps> = ({
  isShowing,
  isLoading,
  handleClose,
  createOrganization,
  updateOrganization,
  organization,
}) => {
  const { t } = useTranslation();

  const { register, handleSubmit, errors } = useForm({
    defaultValues: organization,
    resolver: yupResolver(schema),
  });

  const onSubmit = (organization: IOrganization) => {
    organization.id
      ? updateOrganization.mutate(organization)
      : createOrganization.mutate(organization);
  };

  return (
    <Modal isShowing={isShowing} handleClose={handleClose}>
      <ModalContent>
        <form onSubmit={handleSubmit(onSubmit)} data-testid="form">
          {organization.id && <input type="hidden" name="id" ref={register} />}
          <ModalHeader
            headerText={
              organization.id
                ? t("organization.updateOrganization")
                : t("organization.createOrganization")
            }
            handleClose={handleClose}
          />
          <ModalBody>
            <FieldGroup>
              <Input
                type="text"
                label={t("organization.organizationName")}
                name="name"
                errors={errors}
                register={register}
              />
              <Input
                type="text"
                label={t("common.groupCode")}
                name="code"
                errors={errors}
                register={register}
              />
            </FieldGroup>
            <FieldGroup>
              <Input
                type="text"
                label={t("common.address1")}
                name="address1"
                errors={errors}
                register={register}
              />
              <Input
                type="text"
                label={t("common.address2")}
                name="address2"
                errors={errors}
                register={register}
              />
            </FieldGroup>
            <FieldGroup>
              <Input
                type="text"
                label={t("common.city")}
                name="city"
                errors={errors}
                register={register}
              />
              <Input
                type="text"
                label={t("common.state")}
                name="territory"
                errors={errors}
                register={register}
              />
            </FieldGroup>
            <FieldGroup>
              <Input
                type="text"
                label={t("common.postcode")}
                name="postcode"
                errors={errors}
                register={register}
              />
              <SelectInput
                label={t("common.country")}
                options={countries}
                name="country"
                errors={errors}
                register={register}
              />
            </FieldGroup>
          </ModalBody>
          <ModalFooter>
            <Button
              type="button"
              size="medium"
              variant="secondary"
              onClick={handleClose}
            >
              {t("common.cancel")}
            </Button>
            <LoadingButton
              type="submit"
              size="medium"
              isLoading={isLoading}
              isDisabled={false}
              variant="primary"
            >
              {t("common.submit")}
            </LoadingButton>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
};

export default OrganizationModal;
