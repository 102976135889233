import React from "react";
import { BrowserRouter as Router } from "react-router-dom";

import { QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { ThemeProvider } from "styled-components";

import { queryClient } from "./reactQuery/queryClient";
import GlobalStyle from "./styles/GlobalStyle";
import theme from "./styles/theme";
import OuterAppWrapper from "./components/OuterAppWrapper";

const App: React.FC = () => {
  console.log("[APP] Received app");
  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={theme}>
        <GlobalStyle />
        <Router>
          <OuterAppWrapper />
        </Router>
      </ThemeProvider>
      <ReactQueryDevtools />
    </QueryClientProvider>
  );
};

export default App;
