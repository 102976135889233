import React from "react";
import styled from "styled-components";

import { SuccessIcon } from "./Icons";
interface IProps {
  readonly children: React.ReactNode;
}

const StyledSpan = styled.div`
  color: ${({ theme }) => theme.colors.successText};
  background-color: ${({ theme }) => theme.colors.successBackground};
  border-radius: 0.375rem;
  padding: 0.625rem;
  display: flex;
  align-items: top;
  > svg {
    display: inline-block;
    transform: scale(1.2);
    margin-top: 0.25rem;
  }
  > span {
    display: inline-block;
    padding: 0.375rem;
  }
`;

const SuccessText: React.FC<IProps> = ({ children }) => {
  return (
    <StyledSpan>
      <SuccessIcon />
      <span>{children}</span>
    </StyledSpan>
  );
};

export default SuccessText;
