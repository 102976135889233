import React from "react";
import styled, { keyframes } from "styled-components";

import { Button } from "../components";
interface IProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  readonly children: React.ReactNode;
  isLoading: Boolean;
  isDisabled: boolean;
  size?: string;
  variant?: string;
}

const rotate = keyframes`;
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

const Spinner = styled.div.attrs({
  "data-testid": "spinner",
})`
  display: inline-block;
  border: 4px solid rgba(255, 255, 255, 0.2);
  border-left: 4px solid;
  animation: load 1s infinite linear;
  border-radius: 50%;
  width: 1.563rem;
  height: 1.563rem;
  margin-right: 0.5rem;
  animation: ${rotate} 1s linear infinite;
`;

const StyledButton = styled(Button)`
  transition: all 0.2s;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const LoadingButton: React.FC<IProps> = ({
  children,
  isLoading,
  isDisabled,
  size,
  variant,
}) => {
  return (
    <StyledButton disabled={isDisabled} size={size} variant={variant}>
      {isLoading && <Spinner />}
      {children}
    </StyledButton>
  );
};

export { LoadingButton };
