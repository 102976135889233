import styled from "styled-components";

interface IProps {
  size?: number;
  isSquare?: boolean;
}

const IconButton = styled.button<IProps>`
  height: ${({ size }) => size || 2}rem;
  width: ${({ size }) => size || 2}rem;
  padding: 0.25rem;
  background: none;
  fill: #677a89;
  border: none;
  cursor: pointer;
  transition: all 0.2s;
  border-radius: ${({ isSquare }) => (isSquare ? 0 : 50)}%;
  &:hover {
    svg {
      fill: #32424e;
    }
    background-color: #dee4e8;
  }
  &:active {
    svg {
      fill: #000;
    }
    background-color: #c3cdd5;
  }
`;

export { IconButton };
