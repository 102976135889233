import React from "react";
import styled from "styled-components";

interface IProps {
  label: string;
  name: string;
  register?: any;
}

const CheckboxWrapper = styled.div`
  width: 100%;
  margin: 0.5rem auto;
  position: relative;
  display: block;
  label {
    position: relative;
    min-height: 2.125rem;
    display: block;
    padding-left: 2.5rem;
    margin-bottom: 0;
    cursor: pointer;
    span {
      position: absolute;
      top: 50%;
      color: ${({ theme }) => theme.colors.checkboxLabel};
      size: 1.125rem;
      transform: translateY(-50%);
    }
    &:before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      margin: 0.25rem;
      width: 1.5rem;
      height: 1.5rem;
      transition: transform 0.28s ease;
      border-radius: 0.188rem;
      border: 1px solid ${({ theme }) => theme.colors.checkboxBorder};
    }
    &:after {
      content: "";
      display: block;
      width: 0.875rem;
      height: 0.438rem;
      border-bottom: 2px solid ${({ theme }) => theme.colors.checkboxBackground};
      border-left: 2px solid ${({ theme }) => theme.colors.checkboxBackground};
      transform: rotate(-45deg) scale(0);
      transition: transform ease 0.25s;
      position: absolute;
      top: 0.625rem;
      left: 0.563rem;
    }
  }
  input[type="checkbox"] {
    width: auto;
    opacity: 0.00000001;
    position: absolute;
    left: 0;
    margin-left: -1.125rem;
    transition: all 0.2s;
    &:checked ~ label {
      &:before {
        border: 1px solid ${({ theme }) => theme.colors.checkboxSelectedBorder};
        background-color: ${({ theme }) =>
          theme.colors.checkboxSelectedBackground};
      }
      &:after {
        transform: rotate(-45deg) scale(1);
      }
    }
    &:focus + label::before {
      box-shadow: 0 0 0 0.375rem
        ${({ theme }) => theme.colors.checkboxFocusBackground};
      border: 1px solid ${({ theme }) => theme.colors.checkboxFocusBorder};
    }
  }
`;

const Checkbox: React.FC<IProps> = ({ label, name, register }) => {
  return (
    <CheckboxWrapper>
      <input type="checkbox" id={name} name={name} ref={register} />
      <label htmlFor={name}>
        <span>{label}</span>
      </label>
    </CheckboxWrapper>
  );
};

export default Checkbox;
