import React from "react";
import styled from "styled-components";

import { NavigationItem } from "./NavigationItem";

const NavigationWrapper = styled.nav`
  background-color: ${({ theme }) => theme.colors.navigationBackground};
  position: fixed;
  z-index: 402;
  top: 3.25rem;
  left: 0;
  bottom: 0;
`;

const Navigation = () => {
  const navigationItems = [
    {
      to: "/",
      icon: "OrganizationIcon",
      text: "organization.organizations",
      subject: "Organization",
    },
    {
      to: "/site",
      icon: "SiteIcon",
      text: "site.sites",
      subject: "Site",
    },
  ];

  return (
    <NavigationWrapper>
      {navigationItems.map((navigationItem) => {
        return (
          <NavigationItem
            key={navigationItem.to}
            to={navigationItem.to}
            icon={navigationItem.icon}
            text={navigationItem.text}
            subject={navigationItem.subject}
          />
        );
      })}
    </NavigationWrapper>
  );
};

export { Navigation };
