import React, { useMemo, useEffect } from "react";
import { useTable, useSortBy } from "react-table";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import ReactTooltip from "react-tooltip";

import "../../i18n";
import {
  Table,
  TableActions,
  TableColumnSort,
  IconButton,
  Scroller,
  Spinner,
  Button,
} from "../../components";
import { EditIcon, DeleteIcon } from "../../components/Icons";
import { Can } from "../../components/Abilities";
import { ISite, IOrganization } from "../../types/interfaces";
interface IProps {
  isLoading: boolean;
  data: any;
  handleCreateSite: () => void;
  handleUpdateSite: (site: any) => void;
  handleDeleteSite: (site: any) => void;
}

const TableHeader = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  padding-bottom: 0.6rem;
`;

const SiteTable: React.FC<IProps> = ({
  isLoading,
  data,
  handleCreateSite,
  handleUpdateSite,
  handleDeleteSite,
}) => {
  const { t } = useTranslation();

  useEffect(() => {
    ReactTooltip.rebuild();
  });

  const columns: any[] = useMemo(
    () => [
      {
        Header: t("site.id"),
        accessor: "id",
      },
      {
        Header: t("site.name"),
        accessor: "name",
      },
      {
        Header: t("site.domainName"),
        accessor: "domainName",
      },
      {
        Header: t("organization.organization"),
        accessor: "organizationName",
      },
      {
        Header: t("site.systemType"),
        Cell: ({ row }: any) => {
          return row?.original?.systemType?.replace(/-/g, " ");
        },
      },
      {
        Header: t("common.actions"),
        Cell: ({ row }: any) => {
          return (
            <TableActions>
              {/* <Can I="update" a="Site"> */}
              <IconButton
                data-for="action"
                data-tip={t("common.update")}
                data-testid="update"
                onClick={() => handleUpdateSite(row.original)}
              >
                <EditIcon />
              </IconButton>
              {/* </Can> */}
              {/* <Can I="delete" a="Site">
                <IconButton
                  data-for="action"
                  data-tip={t("common.delete")}
                  data-testid="delete"
                  onClick={() => handleDeleteSite(row.original)}
                >
                  <DeleteIcon />
                </IconButton>
              </Can> */}
            </TableActions>
          );
        },
      },
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
    },
    useSortBy
  );

  return (
    <>
      <TableHeader>
        <h2>{t("site.sites")}</h2>
        {/* <Can I="create" a="Site">
          <div>
            <Button onClick={handleCreateSite} size="small" variant="primary">
              {t("site.createSite")}
            </Button>
          </div>
        </Can> */}
      </TableHeader>
      <Scroller>
        <Table {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                    <span>{column.render("Header")}</span>
                    <TableColumnSort column={column} />
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row, i) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </Table>
        {/* <Spinner isLoading={isLoading} /> */}
      </Scroller>
      <ReactTooltip id="action" effect="solid" />
    </>
  );
};

export default SiteTable;
