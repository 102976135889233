import React, { useState, useEffect, useCallback } from "react";
import styled from "styled-components";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import { motion } from "framer-motion";
import { countBy } from "lodash";
import { useHistory, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import "../../i18n";

import {
  ArrowForward,
  Fullscreen,
  FullscreenExit,
} from "../../components/Icons";
import { IconButton } from "../../components/IconButton";
import DashboardMap from "./DashboardMap";
import DashboardDetail from "./DashboardDetail";
import DashboardSidebar from "./DashboardSidebar";
import { useSites } from "./hooks/useSites";

const StyledFullScreen = styled(FullScreen)`
  position: relative;
  background: #fff;
  margin: -1.25rem;
`;

const HeaderWrapper = styled.div`
  display: grid;
  grid-template-columns: auto 1fr auto;
  > button,
  div {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.563rem;
    border-top: 1px solid #dee4e8;
    border-right: 1px solid #dee4e8;
    border-bottom: 1px solid #dee4e8;
  }
`;
const MapButtons = styled(motion.div)`
  position: absolute;
  top: 0;
  right: 0;
  display: inline-block;
  z-index: 1000;
  background-color: #fff;
  display: grid;
  grid-template-columns: auto auto;
  > button,
  div {
    transform: rotate(180deg);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.563rem;
    border: 1px solid #dee4e8;
  }
`;

const SideNav = styled(motion.div)`
  display: flex;
  z-index: 1001;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: #fff;
  min-width: 21.25rem;
  flex-direction: column;
  transform: translateX(-100%);
`;

interface RouteParams {
  id: any;
}

const Dashboard: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  //const { site_id } = useParams<RouteParams>();
  const [siteId, setSiteId] = useState(null);
  const [isOpen, setIsOpen] = useState(true);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const { isLoading, data = [] } = useSites();
  const [siteCount, setSiteCount] = useState(0);
  const [onlineCount, setOnlineCount] = useState(0);

  const handleFullScreenClick = useFullScreenHandle();

  const fullScreenChange = useCallback(
    (state) => {
      setIsFullScreen(state);
    },
    [handleFullScreenClick]
  );

  useEffect(() => {
    setSiteCount(data.length);
    setOnlineCount(
      countBy(data, (site) => site.state === 1 || site.state === 2).true
    );
    //if (site_id) setSiteId(site_id);
  }, [data]);

  const handleSiteIdClick = (siteId: any) => {
    if (siteId) {
      setSiteId(siteId);
      history.push(`/dashboard/${siteId}`);
    }
  };

  const variants = {
    open: { opacity: 1, x: 0 },
    closed: { opacity: 0, x: "100%" },
  };

  return (
    <StyledFullScreen
      handle={handleFullScreenClick}
      onChange={fullScreenChange}
    >
      <DashboardMap
        data={data}
        selectedSiteId={siteId}
        isFullScreen={isFullScreen}
        handleSiteIdClick={handleSiteIdClick}
      />

      <SideNav
        transition={{ type: "tween" }}
        animate={isOpen ? "open" : "closed"}
        variants={variants}
      >
        <HeaderWrapper>
          <IconButton
            size={2.375}
            isSquare={true}
            onClick={() => setIsOpen((prevState) => !prevState)}
          >
            <ArrowForward />
          </IconButton>
          <div>
            <strong>{onlineCount}</strong>
            &nbsp;{t("dashboard.of")}&nbsp;
            <strong>{siteCount}</strong>
            &nbsp;{t("dashboard.sitesOnline")}
          </div>
          {/* {!handleFullScreenClick.active && (
            <IconButton
              size={2.375}
              isSquare={true}
              onClick={handleFullScreenClick.enter}
            >
              <Fullscreen />
            </IconButton>
          )}
          {handleFullScreenClick.active && (
            <IconButton
              size={2.375}
              isSquare={true}
              onClick={handleFullScreenClick.exit}
            >
              <FullscreenExit />
            </IconButton>
          )} */}
        </HeaderWrapper>
        <DashboardDetail sites={data} selectedSiteId={siteId} />
        <DashboardSidebar
          loading={isLoading}
          data={data}
          selectedSiteId={siteId}
          handleSiteIdClick={handleSiteIdClick}
        />
      </SideNav>
      <MapButtons
        transition={{ type: "tween" }}
        animate={isOpen ? "closed" : "open"}
        variants={variants}
      >
        <IconButton
          size={2.375}
          isSquare={true}
          onClick={() => setIsOpen((prevState) => !prevState)}
        >
          <ArrowForward />
        </IconButton>
        {/* {!handleFullScreenClick.active && (
          <IconButton
            size={2.375}
            isSquare={true}
            onClick={handleFullScreenClick.enter}
          >
            <Fullscreen />
          </IconButton>
        )}
        {handleFullScreenClick.active && (
          <IconButton
            size={2.375}
            isSquare={true}
            onClick={handleFullScreenClick.exit}
          >
            <FullscreenExit />
          </IconButton>
        )} */}
      </MapButtons>
    </StyledFullScreen>
  );
};

export default Dashboard;
