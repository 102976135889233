import { axiosInstance } from "../../../axiosInstance";
import { useMutation, useQueryClient } from "react-query";
import { queryKeys } from "../../../reactQuery/constants";

const useDeleteOrganization = () => {
  const queryClient = useQueryClient();
  const mutation = useMutation(
    (organizationId) =>
      axiosInstance.delete(`/v1/organizations/${organizationId}`),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(queryKeys.organizations);
      },
    }
  );

  return mutation;
};

export { useDeleteOrganization };
