import { axiosInstance } from "../../../axiosInstance";
import { useMutation, useQueryClient } from "react-query";
import { queryKeys } from "../../../reactQuery/constants";

const useUpdateOrganization = () => {
  const queryClient = useQueryClient();
  const mutation = useMutation(
    (organization: any) =>
      axiosInstance.put(`/v1/organizations/${organization.id}`, organization),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(queryKeys.organizations);
      },
    }
  );

  return mutation;
};

export { useUpdateOrganization };
