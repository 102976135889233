import React from "react";
import styled from "styled-components";

const StyledModalBody = styled.div`
  padding: 2rem 2rem 1rem 2rem;
  max-height: calc(100vh - 8.75rem);
  overflow-y: auto;
`;

interface IProps {
  readonly children: React.ReactNode;
}

const ModalBody: React.FC<IProps> = ({ children }) => {
  return <StyledModalBody>{children}</StyledModalBody>;
};
export default ModalBody;
