import React from "react";
import styled from "styled-components";
import SimpleTreeMenu from "react-simple-tree-menu";

import TreeMenuItem from "./TreeMenuItem";

const TreeMenuItems = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
`;

interface IProps {
  data: any;
  hasSearch: boolean;
  initialActiveKey: string;
  initialOpenNodes: any;
  onClickItem: any;
}

const TreeMenu: React.FC<IProps> = ({
  data,
  hasSearch,
  initialActiveKey,
  initialOpenNodes,
  onClickItem,
}) => {
  return (
    <SimpleTreeMenu
      data={data}
      hasSearch={hasSearch}
      initialActiveKey={initialActiveKey}
      initialOpenNodes={initialOpenNodes}
      onClickItem={onClickItem}
    >
      {({ search, items }) => (
        <TreeMenuItems>
          {items.map(({ key, ...props }) => (
            <TreeMenuItem key={key} {...props} />
          ))}
        </TreeMenuItems>
      )}
    </SimpleTreeMenu>
  );
};

export default TreeMenu;
