import styled from "styled-components";

const LinkButton = styled.button`
  background: none;
  border: none;
  padding: 0;
  color: ${({ theme }) => theme.colors.linkText};
  text-decoration: underline;
  cursor: pointer;
`;

export { LinkButton };
