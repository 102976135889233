import React, { useEffect, useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import { useTranslation } from "react-i18next";

import "../../i18n";
import { useCreateOrganization } from "./hooks/useCreateOrganization";
import { useUpdateOrganization } from "./hooks/useUpdateOrganization";
import { useGetOrganizations } from "./hooks/useGetOrganizations";
import { useDeleteOrganization } from "./hooks/useDeleteOrganization";
import useModal from "../../hooks/useModal";
import OrganizationTable from "./OrganizationTable";
import OrganizationModal from "./OrganizationModal";
import { IOrganization } from "../../types/interfaces";

interface IProps {
  groupCode: string | null;
}

const OrganizationPage: React.FC<IProps> = ({ groupCode }) => {
  console.log("[APP] Organization start");
  const { data = [], isLoading, isError, refetch } = useGetOrganizations();
  const createOrganization = useCreateOrganization();
  const updateOrganization = useUpdateOrganization();
  const deleteOrganization = useDeleteOrganization();
  const [organization, setOrganization] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const {
    isShowing: isModalShowing,
    show: showModal,
    hide: hideModal,
  } = useModal();
  const { t } = useTranslation();

  useEffect(() => {
    if (groupCode) {
      refetch();
    }
  }, [groupCode]);

  useEffect(() => {
    if (isError) {
      toast.error(`${t("common.errorRetrievingData")}`);
    }
  }, [isError, t]);

  useEffect(() => {
    if (createOrganization.isSuccess) {
      toast.success(`${t("common.recordCreatedSuccessfully")}`);
      hideModal();
    }
    setIsSubmitting(createOrganization.isLoading);
  }, [createOrganization.isSuccess, createOrganization.isLoading, t]);

  useEffect(() => {
    if (updateOrganization.isSuccess) {
      toast.success(`${t("common.recordUpdatedSuccessfully")}`);
      hideModal();
    }
    setIsSubmitting(updateOrganization.isLoading);
  }, [updateOrganization.isSuccess, updateOrganization.isLoading, t]);

  useEffect(() => {
    if (deleteOrganization.isSuccess) {
      toast.success(`${t("common.recordDeletedSuccessfully")}`);
    }
  }, [deleteOrganization.isSuccess, t]);

  const handleSubmitError = (): void => {
    toast.error(`${t("common.errorSaving")}`);
  };

  const handleCreateOrganization = (): void => {
    setOrganization({ country: "US" });
    showModal();
  };

  const handleUpdateOrganization = (organization: IOrganization): void => {
    setOrganization(organization);
    showModal();
  };

  const handleDeleteOrganization = ({ id }: any): void => {
    deleteOrganization.mutate(id);
  };

  return (
    <>
      <OrganizationTable
        isLoading={isLoading}
        data={data}
        handleCreateOrganization={handleCreateOrganization}
        handleUpdateOrganization={handleUpdateOrganization}
        handleDeleteOrganization={handleDeleteOrganization}
      />
      {isModalShowing && (
        <OrganizationModal
          isShowing={isModalShowing}
          isLoading={isSubmitting}
          organization={organization}
          createOrganization={createOrganization}
          updateOrganization={updateOrganization}
          handleSubmitError={handleSubmitError}
          handleClose={hideModal}
        />
      )}
      <Toaster position="top-center" reverseOrder={true} />
    </>
  );
};

export default OrganizationPage;
