import axios, { AxiosRequestConfig } from "axios";
import { spaComs } from "@viasat/insights-spa-package";

const config: AxiosRequestConfig = {
  baseURL:
    process.env.NODE_ENV === "production"
      ? "https://insights.maritime.viasat.com/api"
      : "http://localhost:8080/api",

  headers: {
    "Content-Type": "application/json",
  },
};

export const axiosInstance = axios.create(config);

axiosInstance.interceptors.request.use(
  (config) => {
    config.headers["Authorization"] = localStorage["token"]
      ? `Bearer ${localStorage["token"]}`
      : "";
    config.headers["Group-Code"] =
      sessionStorage["groupCode"] !== "ALL"
        ? sessionStorage["groupCode"]
        : "Maritime-Insights-Admin";
    config.headers["Environment"] = document.referrer.includes(
      "/insights.viasat.com"
    )
      ? "prod"
      : "test";
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => {
    if (response.status === 401) {
      console.log("Error: 401");
      spaComs.logout();
    }
    return response;
  },
  (error) => {
    return Promise.reject(error);
  }
);
