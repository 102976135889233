import React from "react";
import styled from "styled-components";

const StyledModalFooter = styled.div`
  border-top: solid 1px ${({ theme }) => theme.colors.modalHeaderBorder};
  display: grid;
  grid-template-columns: auto auto;
  column-gap: 0.25rem;
  justify-content: end;
  padding: 0.75rem;
`;

interface IProps {
  readonly children: React.ReactNode;
}

const ModalFooter: React.FC<IProps> = ({ children }) => {
  return <StyledModalFooter>{children}</StyledModalFooter>;
};
export default ModalFooter;
