import React from "react";
import { motion, AnimatePresence } from "framer-motion";
import styled from "styled-components";

interface IProps {
  isLoading: boolean;
}

const SpinnerWrapper = styled(motion.div)`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;

  div {
    position: relative;
    width: 3.625rem;
    height: 3.625rem;
    display: inline-block;
    box-sizing: border-box;
  }
`;
const Panel = styled(motion.div)`
  background-color: ${({ theme }) => theme.colors.spinnerBackground};
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
`;

const spinTransition = {
  loop: Infinity,
  ease: "linear",
  duration: 1,
};

const Spinner: React.FC<IProps> = ({ isLoading }) => {
  return (
    <AnimatePresence>
      {isLoading && (
        <>
          <SpinnerWrapper
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.3 }}
          >
            <div role="alert" aria-busy="true">
              <motion.svg animate={{ rotate: 360 }} transition={spinTransition}>
                <defs>
                  <linearGradient
                    id="spinner-gradient-1"
                    x1="0%"
                    y1="0%"
                    x2="100%"
                    y2="100%"
                  >
                    <stop offset="0%" stopColor="#61DED8"></stop>
                    <stop offset="100%" stopColor="#3EC1BE"></stop>
                  </linearGradient>
                  <linearGradient
                    id="spinner-gradient-2"
                    x1="100%"
                    y1="0%"
                    x2="0%"
                    y2="100%"
                  >
                    <stop offset="0%" stopColor="#3EC1BE"></stop>
                    <stop offset="100%" stopColor="#0AA5AB"></stop>
                  </linearGradient>
                  <linearGradient
                    id="spinner-gradient-3"
                    x1="100%"
                    y1="100%"
                    x2="0%"
                    y2="0%"
                  >
                    <stop offset="0%" stopColor="#0AA5AB"></stop>
                    <stop offset="100%" stopColor="#008085"></stop>
                  </linearGradient>
                </defs>
                <path
                  strokeWidth="3"
                  fill="none"
                  d="M 1 28 A 27 27 0 0 1 28 1"
                  stroke="#DEE4E8"
                ></path>
                <path
                  strokeWidth="3"
                  fill="none"
                  d="M 28 1 A 27 27 0 0 1 55 28"
                  stroke="url(#spinner-gradient-1)"
                ></path>
                <path
                  strokeWidth="3"
                  fill="none"
                  d="M 55 28 A 27 27 0 0 1 28 55"
                  stroke="url(#spinner-gradient-2)"
                ></path>
                <path
                  strokeWidth="3"
                  fill="none"
                  d="M 28 55 A 27 27 0 0 1 1 28"
                  stroke="url(#spinner-gradient-3)"
                ></path>
              </motion.svg>
            </div>
          </SpinnerWrapper>
          <Panel
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.3 }}
          ></Panel>
        </>
      )}
    </AnimatePresence>
  );
};

export { Spinner };
